export const WORDS = [
   'Acorn',
   'Acres',
   'Actor',
   'Annex',
   'Apply',
   'Arbor',
   'Avant',
   'Bands',
   'Bench',
   'Bikes',
   'Blood',
   'Bloom',
   'Board',
   'Bones',
   'Books',
   'Brain',
   'Break',
   'Brown',
   'Build',
   'Bunce',
   'Canoe',
   'Cheer',
   'Child',
   'Chime',
   'Choir',
   'Civil',
   'Class',
   'Clubs',
   'Cords',
   'Creek',
   'Cycle',
   'Deans',
   'Disco',
   'Donor',
   'Earth',
   'Essay',
   'Event',
   'Exams',
   'First',
   'Frost',
   'Furry',
   'Gifts',
   'Glass',
   'Globe',
   'Grant',
   'Greek',
   'Green',
   'Henry',
   'Holly',
   'Honor',
   'Horse',
   'Hotel',
   'Image',
   'James',
   'Laser',
   'Learn',
   'Major',
   'Match',
   'Minor',
   'Mixer',
   'Music',
   'Nexus',
   'Nurse',
   'Peach',
   'Pride',
   'Proud',
   'Queen',
   'Quiet',
   'Radio',
   'Relay',
   'Santa',
   'Sauce',
   'Serve',
   'Shack',
   'Shave',
   'Smart',
   'Space',
   'Spicy',
   'Sport',
   'Stars',
   'Stone',
   'Storm',
   'Study',
   'Suite',
   'Susan',
   'Teach',
   'Think',
   'Today',
   'Torch',
   'Tours',
   'Tower',
   'Train',
   'Triad',
   'Truck',
   'Value',
   'Venue',
   'Write',
   'Women',
   'World',
]
